<app-header></app-header>
<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<bit-container *ngIf="!loading">
  <!-- Account Credit -->
  <ng-container>
    <h2 bitTypography="h2">
      {{ "accountCredit" | i18n }}
    </h2>
    <p class="tw-text-lg tw-font-bold">{{ accountCredit | currency: "$" }}</p>
    <p bitTypography="body1">{{ "creditAppliedDesc" | i18n }}</p>
    <button type="button" bitButton buttonType="secondary" [bitAction]="addAccountCredit">
      {{ "addCredit" | i18n }}
    </button>
  </ng-container>
  <!-- Payment Method -->
  <ng-container>
    <h2 class="spaced-header">{{ "paymentMethod" | i18n }}</h2>
    <p *ngIf="!hasPaymentMethod">{{ "noPaymentMethod" | i18n }}</p>
    <app-verify-bank-account
      [onSubmit]="verifyBankAccount"
      (verificationSubmitted)="onDataUpdated()"
      *ngIf="hasUnverifiedPaymentMethod"
    />
    <ng-container *ngIf="hasPaymentMethod">
      <p>
        <i class="bwi bwi-fw" [ngClass]="paymentMethodClass"></i>
        {{ paymentMethodDescription }}
      </p>
    </ng-container>
    <button type="button" bitButton buttonType="secondary" [bitAction]="changePaymentMethod">
      {{ (hasPaymentMethod ? "changePaymentMethod" : "addPaymentMethod") | i18n }}
    </button>
  </ng-container>
  <!-- Tax Information -->
  <ng-container>
    <h2 class="spaced-header">{{ "taxInformation" | i18n }}</h2>
    <p>{{ "taxInformationDesc" | i18n }}</p>
    <app-manage-tax-information
      *ngIf="taxInformation"
      [taxInformation]="taxInformation"
      [onSubmit]="updateTaxInformation"
      (taxInformationUpdated)="onDataUpdated()"
    />
  </ng-container>
</bit-container>
